import { render, staticRenderFns } from "./personModal.vue?vue&type=template&id=64bf613c&scoped=true"
import script from "./personModal.vue?vue&type=script&lang=js"
export * from "./personModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64bf613c",
  null
  
)

export default component.exports