import { render, staticRenderFns } from "./sample_list.vue?vue&type=template&id=736b9595&scoped=true"
import script from "./sample_list.vue?vue&type=script&lang=js"
export * from "./sample_list.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "736b9595",
  null
  
)

export default component.exports