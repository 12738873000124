import Vue from "vue";
import VueRouter from "vue-router";
import login from "@/views/login.vue";

import home from "@/views/home.vue";
import project_list from "@/views/project/project_list";
import draft from "@/views/project/draft";

import questionnaire_list from "@/views/project/questionnaire_list";
import questionnaire_user_list from "@/views/project/questionnaire_user_list";
import diary_list from "@/views/project/diary_list";
import add_project from "@/views/project/add_project";
import add_questionnaire from "@/views/project/add_questionnaire";

import goods_list from "@/views/goods/goods_list";

import customer_list from "@/views/customer/customer_list";
import customer_analysis from "@/views/customer/customer_analysis";
import customer_zi_list from "@/views/customer/customer_zi_list";

import volunteer_list from "@/views/volunteer/volunteer_list";

import person_list from "@/views/system/person_list";
import role_list from "@/views/system/role_list";
import project_type_list from "@/views/system/project_type_list";
import project_tag_list from "@/views/system/project_tag_list";
import code_set from "@/views/system/code_set";
import notice_list from "@/views/system/notice_list";
import depart_list from "@/views/system/depart_list";

import log_list from "@/views/statistics/log_list";
import rank_list from "@/views/rank/rank_list";

import questionnaire_template from "@/views/project/questionnaire_template";

import clock_list from "@/views/project/clock_list";

import sample_list from "@/views/sample/sample_list";

import zyz_tag_list from "@/views/system/zyz_tag_list";

import test_tag_list from "@/views/system/test_tag_list";

import yuyue_list from "@/views/project/yuyue_list";

import analysis from "@/views/dashboard/analysis";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: home,
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/home",
    name: "home",
    component: home,
    children: [
      {
        path: "/analysis",
        name: "analysis",
        component: analysis,
      },
      {
        path: "/goods_list",
        name: "goods_list",
        component: goods_list,
      },
      {
        path: "/customer_list",
        name: "customer_list",
        component: customer_list,
      },
      {
        path: "/volunteer_list",
        name: "volunteer_list",
        component: volunteer_list,
      },

      {
        path: "/project_list",
        name: "project_list",
        component: project_list,
      },
      {
        path: "/draft",
        name: "draft",
        component: draft,
      },

      {
        path: "/diary_list",
        name: "diary_list",
        component: diary_list,
      },
      {
        path: "/customer_analysis",
        name: "customer_analysis",
        component: customer_analysis,
      },
      {
        path: "/project_type_list",
        name: "project_type_list",
        component: project_type_list,
      },
      {
        path: "/project_tag_list",
        name: "project_tag_list",
        component: project_tag_list,
      },
      {
        path: "/code_set",
        name: "code_set",
        component: code_set,
      },
      {
        path: "/role_list",
        name: "role_list",
        component: role_list,
      },
      {
        path: "/person_list",
        name: "person_list",
        component: person_list,
      },
      {
        path: "/log_list",
        name: "log_list",
        component: log_list,
      },
      {
        path: "/notice_list",
        name: "notice_list",
        component: notice_list,
      },
      {
        path: "/add_project",
        name: "add_project",
        component: add_project,
      },
      {
        path: "/add_questionnaire",
        name: "add_questionnaire",
        component: add_questionnaire,
      },
      {
        path: "/questionnaire_list",
        name: "questionnaire_list",
        component: questionnaire_list,
      },
      {
        path: "/questionnaire_user_list",
        name: "questionnaire_user_list",
        component: questionnaire_user_list,
      },
      {
        path: "/rank_list",
        name: "rank_list",
        component: rank_list,
      },
      {
        path: "/depart_list",
        name: "depart_list",
        component: depart_list,
      },
      {
        path: "/questionnaire_template",
        name: "questionnaire_template",
        component: questionnaire_template,
      },
      {
        path: "/clock_list",
        name: "clock_list",
        component: clock_list,
      },
      {
        path: "/sample_list",
        name: "sample_list",
        component: sample_list,
      },
      {
        path: "/customer_zi_list",
        name: "customer_zi_list",
        component: customer_zi_list,
      },
      {
        path: "/zyz_tag_list",
        name: "zyz_tag_list",
        component: zyz_tag_list,
      },
      {
        path: "/test_tag_list",
        name: "test_tag_list",
        component: test_tag_list,
      },
      {
        path: "/yuyue_list",
        name: "yuyue_list",
        component: yuyue_list,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

//路由相同时处理
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

export default router;
