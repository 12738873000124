<!-- 新增项目 -->
<template>
  <a-card>
    <edit-modal />
  </a-card>
</template>

<script>
import editModal from "./modal/edit_questionnaire";

export default {
  data() {
    return {};
  },

  components: {editModal},

  computed: {},

  created() {},

  mounted() {},

  methods: {},
};
</script>

<style scoped>
.title {
  font-weight: bold;
  color: black;
  font-size: 15px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
