<!-- 功效评价 -->
<template>
  <a-card>
    <a-form
      @keyup.enter.native="$refs.table.commitProxy('reload')"
      class="myForm"
    >
      <a-row :gutter="24">
        <a-col :xl="6" :lg="6" :md="6" :sm="24">
          <a-form-item>
            <a-button
              type="primary"
              @click="$refs.table.commitProxy('reload')"
              icon="search"
              >查询</a-button
            >
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      highlight-hover-row
      @checkbox-change="selectChangeEvent"
      @checkbox-all="selectAllEvent"
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{trigger: 'cell'}"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config2="tableToolbar"
    >
      <template #toolbar_buttons="{ record }"> </template>
      <template v-slot:action="{row}">
        <a @click="previewEvent(row)">预览</a>
      </template>
    </vxe-grid>

    <vxe-modal
      style="z-index: 10"
      v-model="detailModal"
      title="问卷详情"
      width="1200"
      height="80%"
      resize
      destroy-on-close
      show-zoom
    >
      <questionDetail style="width: 100%" :project_id="project_id" />
    </vxe-modal>

    <preview ref="preview" :template="template"></preview>
  </a-card>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import questionDetail from "./modal/question_detail";
import preview from "@/components/preview";
export default {
  data() {
    let _this = this;
    return {
      locale,
      detailModal: false,
      project_id: "",
      queryParam: {
        createBegin: "",
        createEnd: "",
        projectName: "",
        projectNO: "",
      },
      selectedRowKeys: [],
      selectionRows: [],
      url: {
        list: _this.$Interface.prod_list,
      },
      template: {
        currentIndex: null,
        editForm: {},
        name: "问卷标题",
        number: "",
        brief: "",
        item: [],
      },
      columns: [
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({seq}) => {
              return seq;
            },
          },
        },
        {
          title: "操作",
          field: "action",
          width: 150,
          slots: {
            default: "action",
          },
        },
        {
          title: "项目名称",
          field: "projectName",
        },
        {
          title: "项目编号",
          field: "number",
        },
        {
          title: "提交时间",
          field: "createdAt",
        },
        {
          title: "开始时间",
          field: "beginTime",
        },
        {
          title: "结束时间",
          field: "endTime",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: {storage: true},
        // 列操作记录
        custom: {storage: true},
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data",
          // total: "data.totalCount",
        },
        ajax: {
          query: ({page, sort, filters, form}) => {
            return _this.$postAction(this.$Interface.questionnair_user_list);
          },
        },
      },
      subModal: false,
      selectRow: null,
    };
  },

  components: {
    questionDetail,
    preview,
  },

  Previewcomputed: {},

  created() {
    let data = {
      path: "questionnaire_user_list",
      title: "调查问卷查询",
      index: "sub1",
    };
    this.$emit("change_bread", data);
  },

  mounted() {},

  methods: {
    selectChangeEvent({checked, records}) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    selectAllEvent({checked, records}) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    searchReset() {
      this.queryParam = {
        createBegin: "",
        createEnd: "",
        projectName: "",
        projectNO: "",
        status: "",
        projectType: "",
      };
      this.loadData();
    },
    loadData() {
      this.$refs.table.commitProxy("query");
    },
    see(row) {
      this.project_id = row.id;
      this.detailModal = true;
    },
    previewEvent(row) {
      console.log("row", row);
      let data = JSON.parse(row.projectAnswer);
      console.log(data);
      this.template = {
        currentIndex: null,
        editForm: {},
        name: row.projectName,
        number: data.number,
        // brief: data.brief,
        item: data ? data : [],
      };
      this.preview();
    },
    preview() {
      this.$refs.preview.showModal();
    },
  },
};
</script>

<style scoped lang="scss">
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.leftV {
  width: 350px;
}

.rightV {
  width: 375px;
  height: 667px;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  margin-left: 120px;
  padding: 10px;
  overflow: hidden;
}

.title {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  border: none;
  color: black;
}

.itemLabel {
  font-weight: bold;
  border: none;
}

.closeBadeg {
  color: red;
  cursor: pointer;
  z-index: 1;
}

.gutter-box {
  width: 100%;
  height: calc(100% - 30px);
  padding-bottom: 50px;
  overflow: auto;
}

.edit {
  border: 1px dashed red;
}

.btV {
  margin: 15px;
}

/*滚动条样式*/
.gutter-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #fafafa;
  border-radius: 20px;
  background-color: #f5f5f5;
}
.gutter-box::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}
.gutter-box::-webkit-scrollbar-thumb {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 5px #fafafa;
  background-color: #555;
}

.item {
  cursor: move;
}

.dragItem {
  position: relative;
}

.close {
  color: red;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
}

.itemEdit {
  color: #1890ff;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
}

.formItem {
  position: relative;
  padding: 0 5px;
  padding-top: 10px;
  margin-bottom: 10px;
}
</style>

<style lang="scss">
.rightV {
  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    display: block;
  }
  .ant-form-item-label {
    white-space: normal;
    text-align: left;
    line-height: normal;
    font-weight: bold;
    font-size: 15px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
}
</style>
