import { render, staticRenderFns } from "./col_sta.vue?vue&type=template&id=e4723230&scoped=true"
import script from "./col_sta.vue?vue&type=script&lang=js"
export * from "./col_sta.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4723230",
  null
  
)

export default component.exports