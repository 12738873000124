<!--  -->
<template>
  <div id="edit">
    <a-form-model
      ref="ruleForm"
      :model="formData"
      :rules="formRules"
      class="ant-advanced-search-form"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-col span="24">
        <div class="title" style="width: 100%">调查问卷信息</div>
      </a-col>

      <a-row :gutter="24">
        <a-col span="24">
          <div class="title">
            调查问卷
            <a-button
              type="primary"
              style="margin-left: 10px"
              size="small"
              @click="seeQuestion"
              >添加调查问卷</a-button
            >
          </div>
        </a-col>

        <a-col span="24">
          <vxe-grid
            style="margin: 10px 0"
            size="small"
            resizable
            row-id="id"
            show-overflow
            show-header-overflow
            highlight-hover-row
            border
            :data="question_data"
            :columns="columns"
          >
            <template v-slot:action="{ row }">
              <a @click="preview(row)">预览</a>
              <!-- <a style="margin-left: 8px" @click="removeEvent(row)">删除</a> -->
              <a-popconfirm
                title="确认删除?"
                ok-text="确定"
                cancel-text="取消"
                @confirm="removeEvent(row)"
              >
                <a href="#" style="margin-left: 8px">删除</a>
              </a-popconfirm>
            </template>
            <template v-slot:start_time="{ row }">
              <a-date-picker
                placeholder="请选择"
                format="YYYY-MM-DD HH:mm"
                valueFormat="YYYY-MM-DD HH:mm"
                :locale="locale"
                v-model="row.begin_time"
                :show-time="{ format: 'HH:mm' }"
              ></a-date-picker>
            </template>
            <template v-slot:end_time="{ row }">
              <a-date-picker
                placeholder="请选择"
                format="YYYY-MM-DD HH:mm"
                valueFormat="YYYY-MM-DD HH:mm"
                :locale="locale"
                v-model="row.end_time"
                :show-time="{ format: 'HH:mm' }"
              ></a-date-picker>
            </template>
          </vxe-grid>
        </a-col>

        <a-divider />

        <a-col :span="24" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="submitEvent(0)"> 保存 </a-button>
          <a-button
            type="primary"
            @click="submitEvent(2)"
            style="margin-left: 8px"
            v-if="formData.status < 2"
          >
            提交
          </a-button>
          <a-button style="margin-left: 8px" @click="handleReset">
            清空
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>

    <preview ref="preview" :template="template"></preview>

    <questionList ref="questionList" @ok="selQuestion" />
  </div>
</template>

<script>
import preview from "@/components/preview";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import questionList from "./question_list.vue";
import calendar from "@/components/calendar/calendar";
import selDayTime from "./selDayTime.vue";
// import E from "wangeditor";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { IEditorConfig, createEditor, createToolbar } from "@wangeditor/editor";

export default {
  data() {
    let _this = this;
    return {
      colDelArr: [],
      yuyueDelArr: [],
      collapse0: [0, 1],
      collapse1: [0, 1],
      collapse2: [0, 1],
      doType: "",
      timeModal: false,
      dayRow: {},
      timeSplit: {},
      itemIndex: "",
      optTime: [],
      splitV: 10,
      festival: [],
      restDate: [],
      festivalObj: {},
      dateArr: "",
      locale,
      template: {},
      fileListMain: [],
      questionList: [],
      previewVisible: false,
      previewImage: "",

      status_opt: [
        { label: "未审核", value: "0" },
        { label: "已通过", value: "1" },
        { label: "已拒绝", value: "2" },
        { label: "未开始", value: "3" },
        { label: "进行中", value: "4" },
        { label: "已完成", value: "5" },
      ],
      tagList: [],
      is_opt: [
        {
          label: "是",
          value: "Y",
        },
        {
          label: "否",
          value: "N",
        },
      ],
      sex_opt: [
        {
          label: "全部",
          value: "I",
        },
        {
          label: "男",
          value: "M",
        },
        {
          label: "女",
          value: "F",
        },
      ],
      formData: {},
      formRules: {},
      question_data: [],
      columns: [
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "操作",
          field: "action",
          slots: {
            default: "action",
          },
        },
        {
          title: "调查问卷名称",
          field: "question_name",
        },
        {
          title: "类型",
          field: "type_text",
        },
        {
          title: "开始时间",
          slots: {
            default: "start_time",
          },
        },
        {
          title: "结束时间",
          slots: {
            default: "end_time",
          },
        },
      ],
      selectionRows: "",
      selectedRowKeys: "",
      c_day: "",
      c_month: new Date().getMonth() + 1,
      yuyue_arr: [],
      columnsYu: [
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "操作",
          field: "action",
          slots: {
            default: "action",
          },
          width: 100,
        },
        {
          title: "日期",
          field: "day",
          width: 120,
        },
        {
          title: "时间段",
          slots: {
            default: "timeArr",
          },
        },
      ],
      deleteArr: [],
      editor: null,
      editorConfig: {},
      toolbarConfig: {
        excludeKeys: [
          "insertLink",
          "emotion",
          "editImage",
          "insertImage",
          "uploadImage",
          "insertVideo",
          "uploadVideo",
        ],
      },
    };
  },

  components: {
    preview,
    questionList,
    calendar,
    selDayTime,
    Editor,
    Toolbar,
  },

  computed: {},

  created() {
    this.init();
  },

  mounted() {},

  beforeDestroy() {
    const editor = this.editor;
    if (editor) {
      editor.destroy();
    }
  },

  methods: {
    init() {
      let that = this;
      that.tagList = [];
      that.typeOptions = [];
      that.test_tag = [];
      that.teamList = [];
      let customer_id = "";
      customer_id = JSON.parse(sessionStorage.userInfo).user_id;
      if (JSON.parse(sessionStorage.userInfo).main.customer_id) {
        customer_id = JSON.parse(sessionStorage.userInfo).main.customer_id;
      }
      if (sessionStorage.sf == "管理员") {
        customer_id = "";
      }
      let param =
        "?pageNum=1" + "&pageSize=100" + "&customer_id=" + customer_id;
      that.$getAction(that.$Interface.project_tag_list + param).then((res) => {
        if (res.code == 200) {
          if (res.data.list && res.data.list.length) {
            that.tagList = res.data.list.map((it) => {
              let id = it.id;
              let a = {
                value: id,
                label: it.name,
              };
              return a;
            });
          }
        }
      });

      that.$getAction(that.$Interface.project_type_list + param).then((res) => {
        if (res.code == 200) {
          if (res.data.list && res.data.list.length) {
            that.typeOptions = res.data.list.map((it) => {
              let id = it.id;
              let a = {
                value: id,
                label: it.name,
              };
              return a;
            });
          }
        }
      });
      that
        .$getAction(that.$Interface.tag_list + param + "&tag_type=i")
        .then((res) => {
          if (res.code == 200) {
            if (res.data.list && res.data.list.length) {
              that.test_tag = res.data.list.map((it) => {
                let id = it.id;
                let a = {
                  value: id,
                  label: it.name,
                };
                return a;
              });
            }
          }
        });

      that.$getAction(that.$Interface.group_list + param).then((res) => {
        if (res.code == 200) {
          if (res.data.List && res.data.List.length) {
            that.teamList = res.data.List.map((it) => {
              let id = it.id;
              let a = {
                value: id,
                label: it.team_name,
              };
              return a;
            });
          }
        }
      });
    },
    initEditor(editor) {
      let that = this;
      this.editor = Object.seal(editor);
    },

    seeQuestion() {
      this.$refs.questionList.show();
    },
    selQuestion(row) {
      let that = this;
      let data = JSON.parse(JSON.stringify(row));
      for (let i in data) {
        let a = data[i];
        let question_id = a.id;
        let index = _.findIndex(that.question_data, {
          question_id: question_id,
        });
        if (index == -1) {
          let o = {
            begin_time: "",
            end_time: "",
            question_id: a.id,
            question_name: a.name,
            question_number: a.number,
            type: a.type,
            type_text: a.type_text,
          };
          that.question_data.push(o);
        }
      }
    },
    removeEvent(row) {
      let that = this;
      let index = _.indexOf(that.question_data, row);
      that.question_data.splice(index, 1);

      let idStr = row.id.toString();
      if (idStr.indexOf("row") == -1 && that.deleteArr.indexOf(row.id) == -1) {
        that.deleteArr.push(row.id);
      }
    },

    updateQuestion(obj) {
      this.$postAction(this.$Interface.project_question_update, obj).then(
        (res) => {
          if (res.code == 200) {
            console.log("更新问卷成功");
          } else {
            console.log("更新问卷失败");
          }
        }
      );
    },

    submitEvent(status) {
      let that = this;
      // that.formData.feature = that.editor.getHtml();
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let param = JSON.parse(JSON.stringify(this.formData));

          param.question = that.question_data;

          for (let i in param.question) {
            let a = param.question[i];
            let id = a.id;
            let idStr = id.toString();
            if (idStr.indexOf("row") > -1) {
              (a.project_id = that.formData.id), delete a.id;
            }
            that.updateQuestion(a);
          }
          // let type_index = _.findIndex(that.typeOptions, {
          //   value: param.project_type_id,
          // });
          // if (type_index > -1) {
          //   param.project_type = that.typeOptions[type_index].label;
          // }

          // param.status = status;
          that.$postAction(that.$Interface.project_add, param).then((res) => {
            if (res.code == 200) {
              that.$message.success("操作成功");
              that.$emit("loadData");

              let y_items = that.$refs.yuyueTime.yuyue_arr;
              let y_arr = [];
              for (let i in y_items) {
                let a = y_items[i];
                let o = {
                  items: a.items,
                  base_day: a.base_day,
                };
                y_arr.push(o);
              }

              for (let j in that.yuyueDelArr) {
                let row = that.yuyueDelArr[j];
                let hasIndex = that._.findIndex(y_arr, {
                  base_day: row.base_day,
                });
                if (hasIndex == -1) {
                  y_arr.push(row);
                }
              }

              let arr = {
                days: y_arr,
                project_id: res.data.id,
              };
              that.$postAction(that.$Interface.edit_yuye, arr).then((res) => {
                if (res.code == 200) {
                  // console.log(res);
                } else {
                  that.$message.error(res.message);
                }
              });

              let c_items = that.$refs.clockTime.yuyue_arr;
              let c_arr = [];
              for (let i in c_items) {
                let a = c_items[i];
                let o = {
                  items: a.items,
                  base_day: a.base_day,
                };
                c_arr.push(o);
              }

              for (let k in that.colDelArr) {
                let row = that.colDelArr[k];
                let hasIndex = that._.findIndex(c_arr, {
                  base_day: row.base_day,
                });
                if (hasIndex == -1) {
                  c_arr.push(row);
                }
              }

              let arr2 = {
                days: c_arr,
                project_id: res.data.id,
              };
              that.$postAction(that.$Interface.clock_set, arr2).then((res) => {
                if (res.code == 200) {
                  // console.log(res);
                } else {
                  that.$message.error(res.message);
                }
              });
            } else {
              that.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    handleReset() {
      this.formData = {
        extInfo: {},
        sample_name: "",
        sample_num: "",
      };
      this.fileListMain = [];
    },

    preview(row) {
      let that = this;
      let param = "?id=" + row.question_id;
      that.$getAction(that.$Interface.question_edit + param).then((res) => {
        if (res.code == 200) {
          that.template = res.data;
          this.$refs.preview.showModal();
        }
      });
    },
  },
};
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>

<style scoped>
.title {
  font-weight: bold;
  color: black;
  font-size: 15px;
  height: 30px;
  line-height: 30px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.closeBadeg {
  color: red;
  cursor: pointer;
  z-index: 1;
}

.scissor {
  color: #1890ff;
  cursor: pointer;
  z-index: 1;
}

.rightV {
  width: 375px;
  height: 550px;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  padding: 10px;
  margin: 10px auto;
  overflow: auto;
}
</style>
